export default {
  stats: {
    spacesActives: "{{count}} espace actif",
    spacesActives_plural: "{{count}} espaces actifs",
    actionsCount: "{{count}} participation",
    actionsCount_plural: "{{count}} participations",
    objectivesProposed: "{{count}} objectif proposé",
    objectivesProposed_plural: "{{count}} objectifs proposés",
    solutionsProposed: "{{count}} solution proposée",
    solutionsProposed_plural: "{{count}} solutions proposées",
    solutionsInPlace: "{{count}} solution appliquée",
    solutionsInPlace_plural: "{{count}} solutions appliquées",
  },
};
