import React from "react";
import { Helmet } from "react-helmet";
import { I18nextProvider } from "react-i18next";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQueryClient,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Hydrate } from "react-query/hydration";
import * as Sentry from "@sentry/nextjs";
import { create } from "apisauce";
import { useRouter } from "next/router";
import SimpleReactLightbox from "simple-react-lightbox";

import {
  AuthAction,
  AuthContextProvider,
  AuthState,
  NavigationBar,
} from "@politiker/react-auth/web";
import {
  ApiProvider,
  MembershipProvider,
  PolitikerConfigProvider,
  UIContext,
  useSpaceQuery,
  WordingProvider,
} from "@politiker/react-core/web";
import { SnackbarProvider } from "@politiker/react-snackbar";

import AuthModals from "@/libs/auth/AuthModals";
import { ApplicationBar } from "@/libs/common/ApplicationBar";
import { API_HOST, API_URL } from "@/libs/config";
import i18n from "@/libs/i18n";
import { SnackbarPile } from "@/libs/SnackbarPile";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "leaflet/dist/leaflet.css";
import "../styles/globals.css";

import { Footer } from "../libs/common/Footer";
import { ModalInvolment } from "../libs/engagement";
import { addSession } from "../libs/withSession";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Cache for 5 minutes
      staleTime: 0, //5 * 60 * 1000,
      refetchOnMount: true,
    },
  },
});
const isBrowser = typeof window !== "undefined";

const api = create({ baseURL: "/" });
function AppWithAuth({
  auth,
  Component,
  pageProps,
  onLogout,
}: {
  auth: AuthState;
  Component: any;
  pageProps: any;
  onLogout: () => void;
}) {
  const queryClient = useQueryClient();
  const logoutMutation = useMutation(() => api.delete("/api/session"), {
    onSuccess: () => {
      onLogout();
      queryClient.invalidateQueries("memberships");
    },
  });
  const [dropdownMenuOpen, setDropdownMenuOpen] = React.useState(null);
  const { query, pathname } = useRouter();
  const handlerAuthEvent = React.useCallback(
    async (action: AuthAction) => {
      if (action.type === "SIGN_OUT") {
        logoutMutation.mutate();
        Sentry.configureScope((scope) => scope.setUser(null));
      } else if (action.type === "SET_PROFILE") {
        const profile = action.profile;
        Sentry.setUser({
          id: String(profile.id),
          email: profile.email,
          username: profile.username,
        });
      }
    },
    [logoutMutation]
  );
  const { token, user } = auth || {};
  const spaceSlug = query.space as unknown as string | null;
  const spaceQuery = useSpaceQuery(spaceSlug);
  return (
    <AuthContextProvider
      initialState={{ token, user }}
      onAuthEvent={handlerAuthEvent}
    >
      <MembershipProvider isAuthenticated={Boolean(token)}>
        <ModalInvolment spaceIdOrSlug={spaceSlug} />
        <PolitikerConfigProvider>
          <WordingProvider spaceIdOrSlug={spaceSlug}>
            <SnackbarProvider>
              <SnackbarPile />
              <SimpleReactLightbox>
                <UIContext.Provider
                  value={{ dropdownMenuOpen, setDropdownMenuOpen }}
                >
                  <NavigationBar path={pathname}>
                    <ApplicationBar
                      pathname={pathname}
                      applications={spaceQuery.data?.applications}
                      spaceSlug={spaceSlug}
                    />
                  </NavigationBar>
                  <Component {...pageProps} />
                  <Footer />
                  <AuthModals />
                </UIContext.Provider>
              </SimpleReactLightbox>
            </SnackbarProvider>
          </WordingProvider>
        </PolitikerConfigProvider>
      </MembershipProvider>
    </AuthContextProvider>
  );
}

export default function MyApp({ Component, pageProps }) {
  const { token, user } = pageProps;
  const [authState, setAuthState] = React.useState<AuthState | null>({
    token,
    user,
  });
  return (
    <>
      <Helmet>
        <link href="/favicon.png" rel="shortcut icon" />
      </Helmet>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <ApiProvider url={API_URL} host="gex.demov2.actons.fr">
            {isBrowser && <ReactQueryDevtools initialIsOpen={false} />}
            <Hydrate state={pageProps.dehydratedState}>
              <AppWithAuth
                auth={authState}
                onLogout={() => setAuthState(null)}
                Component={Component}
                pageProps={pageProps}
              />
            </Hydrate>
          </ApiProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  const { req, res } = ctx;
  await addSession(req, res);
  return {
    pageProps: {
      host: req.headers.host,
      token: req.session.get("token"),
      user: req.session.get("user"),
    },
  };
};
