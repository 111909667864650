import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import Link from "next/link";
import styled from "styled-components";

import { useWordings } from "@politiker/react-core/web";

export interface ApplicationBarProps {
  applications?: string[];
  spaceSlug: string;
  pathname: string;
}

const Applications = {
  debate: {
    url: (spaceSlug: string) => `/${spaceSlug}/debats-et-votes/`,
    activePath: "debats-et-votes",
  },
  polls: {
    url: (spaceSlug: string) => `/${spaceSlug}/sondages/`,
    activePath: "sondages",
  },
  issues: {
    url: (spaceSlug: string) => `/${spaceSlug}/signalements/`,
    activePath: "signalements",
  },
  news: {
    url: (spaceSlug: string) => `/${spaceSlug}/actualites/`,
    activePath: "actualites",
  },
};

export function getApplicationLink(
  applications: string[]
): null | ((spaceSlug: string) => string) {
  return applications.length ? Applications[applications[0]]?.url : null;
}

const Navbar = {
  Nav: styled(Nav)`
    width: 100%;
    @media screen and (max-width: 767px) {
      flex-flow: column nowrap;
    }
  `,
  NavItem: styled(NavItem)`
    flex: 1 1 auto;
    text-align: center;
  `,
  NavLink: styled(NavLink)`
    color: rgba(255, 255, 255, 0.5);
    &:hover {
      color: rgba(255, 255, 255, 0.75);
    }

    ${({ $active }) =>
      $active &&
      `
      &, &:hover {
        color: white;
        background-color: #63bcd2;
      }
  `}
  `,
  Divider: styled.li`
    background-color: rgba(255, 255, 255, 0.5);
    width: 1px;
  `,
};

function isActive(pathname: string, activePath: string): boolean {
  const bits = pathname.split("/");
  return bits[2] === activePath;
}

export function ApplicationBar(props: ApplicationBarProps) {
  const { applications, spaceSlug, pathname } = props;
  const { w } = useWordings();

  if (!applications) return null;

  return (
    <Navbar.Nav>
      {applications.map((appId, index) => (
        <React.Fragment key={appId}>
          <Navbar.NavItem>
            <Link passHref href={Applications[appId].url(spaceSlug)}>
              <Navbar.NavLink
                $active={isActive(pathname, Applications[appId].activePath)}
              >
                {w(`${appId}.tab.name`)}
              </Navbar.NavLink>
            </Link>
          </Navbar.NavItem>
          {index !== applications.length - 1 ? <Navbar.Divider /> : null}
        </React.Fragment>
      ))}
    </Navbar.Nav>
  );
}
