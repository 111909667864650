import { IncomingMessage, ServerResponse } from "http";
import {
  GetServerSidePropsResult,
  NextApiRequest,
  NextApiResponse,
} from "next";
import { applySession, Session, withIronSession } from "next-iron-session";

const cookieOptions = {
  password: process.env.SECRET_COOKIE_PASSWORD,
  cookieName: "actons_session",
  cookieOptions: {
    // the next line allows to use the session in non-https environments like
    // Next.js dev mode (http://localhost:3000)
    secure: process.env.NODE_ENV === "production" ? true : false,
  },
};

export type Request = NextApiRequest & {
  cookies?: { [key: string]: any };
};
export type Response = ServerResponse;
export type RequestWithSession = Request & { session: Session };

export type SSRHandler<P extends { [key: string]: any }> = ({
  req,
  res,
}: {
  req: RequestWithSession;
  res: Response;
}) => Promise<GetServerSidePropsResult<P>> | GetServerSidePropsResult<P>;
export type APIHandler<R extends Response> = (
  req: RequestWithSession,
  res: R
) => void;

export type Handler<P extends { [key: string]: any }, R extends Response> =
  | APIHandler<R>
  | SSRHandler<P>;

export function withSession<P, R extends Response>(handler: Handler<P, R>) {
  return withIronSession(handler, cookieOptions);
}

export async function addSession(req: Request, res: Response) {
  await applySession(req, res, cookieOptions);
}

export function withAuthenticatedUser<
  P extends { [key: string]: any },
  R extends Response
>(handler: SSRHandler<P>) {
  return withSession<P, R>((context) => {
    const { req } = context;
    const session = req.session;
    const token = session.get("token");
    if (!token) {
      return { redirect: { destination: "/user/connexion", permanent: false } };
    }

    return handler(context);
  });
}

export function withAnonymouseUser<
  P extends { [key: string]: any },
  R extends Response
>(handler: SSRHandler<P>) {
  return withSession<P, R>((context) => {
    const { req } = context;
    const session = req.session;
    const token = session.get("token");
    if (token) {
      return { redirect: { destination: "/", permanent: false } };
    }

    return handler(context);
  });
}
