import { useMutation } from "react-query";
import { ApisauceInstance } from "apisauce";
import styled from "styled-components";

import { ApiKeyResponse } from "@politiker/react-auth/web";
import {
  useApi,
  ValidationError,
  ValidationErrorPayload,
} from "@politiker/react-core/web";

export type SocialProvider = "facebook";

export async function exchangeCodeForToken(
  { code }: { code: string },
  { api, provider }: { api: ApisauceInstance; provider: SocialProvider }
): Promise<ApiKeyResponse> {
  const response = await api.post<ApiKeyResponse, ValidationErrorPayload>(
    `/authentication/${provider}/?flow=web`,
    { code }
  );
  if (response.status === 400) {
    throw new ValidationError(response.data as ValidationErrorPayload);
  }
  if (!response.ok) throw new Error("Impossible de vous connecter");
  return response.data as ApiKeyResponse;
}
export function useSocialProviderLogin(provider: SocialProvider = "facebook") {
  const api = useApi();
  return useMutation<ApiKeyResponse, Error | ValidationError, { code: string }>(
    ({ code }: { code: string }) => {
      return exchangeCodeForToken({ code }, { api, provider });
    }
  );
}

export const FacebookLoginButton = styled.button.attrs({ type: "button" })`
  background-color: #427ab2;
  color: white;
  border-width: 0;
  display: block;
  width: 100%;
  padding: 0.8rem 0.5rem;
  font-weight: 500;
  font-size: 1.1rem;
`;
