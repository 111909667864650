import React from "react";
import styled from "styled-components";

import {
  TechnicalError,
  useJoinSpaceQuery,
  useSpaceQuery,
} from "@politiker/react-core/web";
import { useSnackbar } from "@politiker/react-snackbar";

import { Button } from "./common/Buttons";

export interface JoinSpaceFormProps {
  spaceIdOrSlug: string;
}

const Actions = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export function JoinSpaceForm(props: JoinSpaceFormProps) {
  const { spaceIdOrSlug } = props;
  const snackbar = useSnackbar();
  const spaceQuery = useSpaceQuery(spaceIdOrSlug);
  const joinSpaceMutation = useJoinSpaceQuery(spaceQuery.data);
  const joinSpace = async () => {
    await joinSpaceMutation.mutateAsync();
    snackbar.success("Vous pouvez participer !");
  };

  if (spaceQuery.isLoading) {
    return <span>Chargement en cours</span>;
  }
  if (spaceQuery.isError) {
    return (
      <TechnicalError error={spaceQuery.error} refetch={spaceQuery.refetch} />
    );
  }
  return (
    <div>
      {spaceQuery.data.participation_info}

      <Actions>
        <Button onClick={joinSpace} as="button" type="button">
          Je participe
        </Button>
      </Actions>
    </div>
  );
}
