import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { setLocale } from "yup";

import fr from "../translations.fr";
// import moment from "moment";
// import "moment/locale/fr";

// moment.locale("fr-FR");

setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: "field_invalid",
    required: ({ path }) => ({ key: "field_required", path }),
  },
  string: {
    min: ({ path, min }) => ({ key: "min_value", path, min }),
    max: ({ path, max }) => ({ key: "max_value", path, max }),
  },
  number: {
    min: ({ min }) => ({ key: "field_too_short", values: { min } }),
    max: ({ max }) => ({ key: "field_too_big", values: { max } }),
  },
});

const resources = {
  fr,
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr",
  fallbackLng: "fr",
});

export default i18n;
