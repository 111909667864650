import React from "react";
import { useMutation } from "react-query";
import { create } from "apisauce";

import { ProfileResponse } from "@politiker/react-auth/web";
import { useSnackbar } from "@politiker/react-snackbar";

export function getSpaceHomePage(profile: ProfileResponse | null): string {
  if (!profile || !profile.memberships?.length) return "/";
  const { apps, slug } = profile.memberships[0];
  if (!apps || !apps.length) {
    return `/${slug}/debats-et-votes`;
  }
  return {
    debate: `/${slug}/debats-et-votes`,
    news: `/${slug}/debats-et-votes`,
    polls: `/${slug}/sondages`,
    issues: `/${slug}/signalements`,
  }[apps[0]];
}

const api = create({ baseURL: "/" });

export function useSignIn(options?: {
  onSuccess?: (profile: ProfileResponse) => void;
}) {
  const snackbar = useSnackbar();
  const loginMutation = useMutation((token: string) =>
    api.post(
      "/api/session",
      { token },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
  );
  return React.useCallback(
    async (token: string) => {
      const response = await loginMutation.mutateAsync(token);
      snackbar.success("Connexion réussie");
      const { user: profile } = response.data as { user: ProfileResponse };
      options?.onSuccess?.(profile);
    },
    [loginMutation, options, snackbar]
  );
}

export function useLoginAfterSignUp({
  onSuccess,
}: { onSuccess?: () => void } = {}) {
  const loginMutation = useMutation((token: string) =>
    api.post(
      "/api/session",
      { token },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
  );
  const done = React.useCallback(
    async (token: string) => {
      const response = await loginMutation.mutateAsync(token);
      onSuccess();
    },
    [loginMutation, onSuccess]
  );

  return done;
}
