import React from "react";
import styled from "styled-components";

import { Palette } from "@politiker/react-core";
import { MessageKind, useSnackbar } from "@politiker/react-snackbar";

const Box = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 400px;
  max-width: 100%;
  z-index: 10000;
`;

const color = {
  success: "white",
  error: "white",
  warning: "black",
  info: "white",
};
const bgColor = {
  success: Palette.green.dark,
  error: Palette.red.dark,
  warning: Palette.yellow.dark,
  info: Palette.blue.dark,
};

const Snack = styled.div<{
  $kind: MessageKind;
}>`
  padding: 1rem 1rem;
  background-color: white;
  box-shadow: 0 0 2px 0 #444444;
  border-radius: 2px;
  margin-top: 1rem;
  color: ${({ $kind }) => color[$kind]};
  background-color: ${({ $kind }) => bgColor[$kind]};
`;

export function SnackbarPile() {
  const { messages } = useSnackbar();
  return (
    <Box>
      {messages.map(({ id, kind, message }) => (
        <Snack key={id} $kind={kind}>
          <div className="snack-message">{message}</div>
        </Snack>
      ))}
    </Box>
  );
}
