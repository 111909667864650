import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { SignInForm, useAuthContext } from "@politiker/react-auth/web";
import { useMembershipContext } from "@politiker/react-core/web";

import { useSignIn } from "./auth";
import { JoinSpaceForm } from "./JoinSpaceForm";

export interface ModalInvolmentProps {
  spaceIdOrSlug: string;
}

export function ModalInvolment(props: ModalInvolmentProps) {
  const authContext = useAuthContext();
  const { showAskEngagement, refuse, accept } = useMembershipContext();

  const title = authContext.isAuthenticated
    ? "Pas encore membre ?"
    : "Pas authentifié";

  const onLogin = useSignIn({
    onSuccess: accept,
  });
  const content = authContext.isAuthenticated ? (
    <JoinSpaceForm spaceIdOrSlug={props.spaceIdOrSlug} />
  ) : (
    <SignInForm onSuccess={onLogin} />
  );
  return (
    <Modal isOpen={showAskEngagement} toggle={refuse}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{content}</ModalBody>
    </Modal>
  );
}
