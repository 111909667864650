import React from "react";
import { useMutation, useQuery } from "react-query";
import OAuth2Login from "react-simple-oauth2-login";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Link from "next/link";
import Router from "next/router";
import qs from "qs";
import styled from "styled-components";

import {
  ProfileResponse,
  SignInForm,
  useAuthContext,
} from "@politiker/react-auth/web";
import {
  getErrorMessage,
  useApi,
  useApiContext,
} from "@politiker/react-core/web";

import { getSpaceHomePage, useSignIn } from "@/libs/auth/utils";

import { FacebookLoginButton, useSocialProviderLogin } from "./social-api";

type SignInModalProps = {
  close: () => void;
};

type Config = {
  socialProviders: {
    facebook?: { clientId: string };
  };
};

const SocialWrapper = styled.div`
  padding-top: 1rem;
`;

function useConfigQuery() {
  const api = useApi();

  return useQuery<Config>("config", async () => {
    const response = await api.get<Config>("/api/config");
    if (!response.ok)
      throw new Error("Error occurred while retrieving the config");
    return response.data;
  });
}

function getStandardErrorMessage(error: Error) {
  const message = getErrorMessage(error);

  if (message.includes("User is already registered")) {
    return "Un compte existe déjà pour cette adresse email.";
    // FIXME
    // Connectez-vous et liez votre compte facebook pour pouvoir vous connecter avec Facebook."
  }

  return "Erreur lors de la connexion";
}

export default function SignInModal(props: SignInModalProps) {
  const { close } = props;
  const { url } = useApiContext();
  const auth = useAuthContext();
  const onSuccess = React.useCallback(
    (profile: ProfileResponse) => {
      const homepage = getSpaceHomePage(profile);
      close();
      Router.push(homepage);
    },
    [close]
  );
  const { data: settings } = useConfigQuery();
  const { facebook } = settings?.socialProviders || {};
  const onLogin = useSignIn({ onSuccess });

  const facebookAuth = useSocialProviderLogin("facebook");
  const loginWithFacebook = async ({ code }: { code: string }) => {
    const response = await facebookAuth.mutateAsync({ code });
    auth.signIn(response.key);
  };
  const fbError =
    facebookAuth.error && getStandardErrorMessage(facebookAuth.error);
  return (
    <Modal isOpen toggle={close}>
      <ModalHeader>Connexion</ModalHeader>
      <ModalBody>
        <SignInForm onSuccess={onLogin}>
          <SignInForm.Actions>
            <a href="#auth:password-reset">Mot de passe oublié</a>
          </SignInForm.Actions>
        </SignInForm>
        <a href="#auth:sign-up">Pas de compte ? Inscrivez-vous !</a>
        <SocialWrapper>
          {facebook && (
            <>
              <OAuth2Login
                authorizationUrl="https://graph.facebook.com/oauth/authorize"
                isCrossOrigin
                responseType="code"
                buttonText="Je me connecte avec Facebook"
                clientId={facebook.clientId}
                redirectUri={`${url}oauth/callback`}
                onSuccess={loginWithFacebook}
                onFailure={console.log}
                render={({ buttonText, ...rest }) => (
                  <FacebookLoginButton {...rest} children={buttonText} />
                )}
              />
              {fbError && <div className="alert alert-danger">{fbError}</div>}
            </>
          )}
        </SocialWrapper>
      </ModalBody>
    </Modal>
  );
}
