import styled from "styled-components";

export const AuthContainer = styled.div`
  max-width: 400px;
  box-shadow: 0 0 5px 0 #aaaaaa;
  margin: 1rem auto;
  padding: 1rem;
  h1 {
    font-size: 1.3rem;
  }
`;

export * from "./utils";
