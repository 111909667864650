import React from "react";

import PasswordResetModal from "./PasswordResetModal";
import SignInModal from "./SignInModal";
import SignUpModal from "./SignUpModal";
import useHashParam from "./useHashChange";

function getNamespaceAndView(hash: string): [string, string] | [null, null] {
  const bits = hash?.split(":");

  if (!bits || bits.length < 2) return [null, null];

  return [bits[0], bits[1]];
}

const Modals = {
  connexion: SignInModal,
  "sign-up": SignUpModal,
  "password-reset": PasswordResetModal,
};

export default function AuthModals() {
  const [currentHash, setHash] = useHashParam();

  const [namespace, view] = getNamespaceAndView(currentHash);
  const close = () => {
    setHash("");
  };

  if (namespace !== "auth" || !view) return null;

  const Modal = Modals[view];
  if (!Modal) return null;

  return <Modal close={close} />;
}
