import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { PasswordResetForm } from "@politiker/react-auth/web";

type PasswordResetModalProps = {
  close: () => void;
};

export default function PasswordResetModal(
  props: PasswordResetModalProps
): React.ReactElement {
  const { close } = props;
  return (
    <Modal isOpen toggle={close}>
      <ModalHeader>Réinitialiser le mot de passe</ModalHeader>
      <ModalBody>
        <PasswordResetForm onSuccess={close} />
      </ModalBody>
    </Modal>
  );
}
