import React from "react";
import { Col, Container, Row } from "reactstrap";
import { faFacebookSquare as faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faTwitterSquare as faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitterSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const FooterContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
  width: 100%;
  text-align: center;
  background-image: linear-gradient(to right, #4ea6c3, #588865);
  h6 {
    font-size: 1.2rem;
  }
  h5,
  h6 {
    font-weight: 800;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      margin: 8px 0;
    }
    a {
      text-decoration: none;
      color: #333;
    }
  }
`;

const ListSocialNetworks = styled.div`
  display: flex;
  flex-flow: row nowrap;
  * {
    margin: 0 0.25rem;
  }
  a {
    color: #333;
  }
`;
export function Footer() {
  return (
    <FooterContainer>
      <Container>
        <Row>
          <Col lg={{ size: 3, order: 1 }} md={{ size: 4, order: 2 }}>
            <h6>A propos</h6>
            <ul className="links">
              <li>
                <a href="/comment-ca-marche">Comment ça marche ?</a>
              </li>
              <li>
                <a href="/equipe">Qui sommes-nous ?</a>
              </li>
              <li>
                <a href="/contact?sujet=nouvel_espace">Ouvrir un espace</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
              <li>
                <a href="/conditions-generales">CGU</a>
              </li>
              <li>
                <a href="/mentions-legales">Mentions légales</a>
              </li>
              <li>
                <a href="/cookies">Politique de cookies</a>
              </li>
              <li>
                <a href="/politique-confidentialite">
                  Politique de confidentialité
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={{ size: 2, order: 2 }} md={{ size: 3, order: 3 }}>
            <h6>Plan du site</h6>
            <ul className="links">
              <li>
                <a href="#">Participez !</a>
              </li>
            </ul>
          </Col>
          <Col lg={{ size: 5, order: 3 }} md={{ size: 12, order: 1 }}>
            <h5>Politiker</h5>
            <p>
              Politiker a développé sa plateforme numérique, actons.fr,
              accessible à tous, qui permet très simplement, de créer du lien,
              remonter des besoins, partager des idées, organiser des actions
              concrètes, réaliser des sondages, partager des informations et
              géolocaliser des signalements sur une carte interactive.
            </p>
            <p>Une organisation de l'Economie Sociale et Solidaire</p>
            <p style={{ textAlign: "center" }}>
              <a href="https://politiker.fr" style={{ color: "white" }}>
                Politiker
              </a>
            </p>
          </Col>
          <Col lg={{ size: 2, order: 4 }} md={{ size: 3, order: 4 }}>
            <h6>Nous suivre</h6>
            <ListSocialNetworks>
              <a
                href="https://www.linkedin.com/company/politiker/"
                rel="noreferrer noopener"
                target="_blank"
              >
                <FontAwesomeIcon icon={faLinkedin} size="3x" />
              </a>
              <a
                href="https://www.facebook.com/politiker.democratie.participative/"
                rel="noreferer noopener"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebook} size="3x" />
              </a>
              <a
                href="https://twitter.com/Politiker_fr"
                rel="noreferer noopener"
                target="_blank"
              >
                <FontAwesomeIcon icon={faTwitter} size="3x" />
              </a>
            </ListSocialNetworks>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
}
