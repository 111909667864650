import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { SignUpForm } from "@politiker/react-auth/web";

import { useLoginAfterSignUp } from "./utils";

type SignUpModalProps = {
  close: () => void;
};

export default function SignUpModal(props: SignUpModalProps) {
  const { close } = props;
  const loginAfterSignUp = useLoginAfterSignUp({ onSuccess: close });
  return (
    <Modal isOpen toggle={close}>
      <ModalHeader>Inscription</ModalHeader>
      <ModalBody>
        <SignUpForm onSuccess={loginAfterSignUp} />
      </ModalBody>
    </Modal>
  );
}
