import React from "react";

function getHashSearchParams(location: Location): string {
  return location.hash.slice(1);
}

function getHashParam(defaultValue = "") {
  if (typeof window === "undefined") {
    return defaultValue;
  }
  return getHashSearchParams(window.location);
}

function setHashParam(value: string) {
  if (typeof window !== "undefined") {
    window.location.hash = value ? value : null;
  }
}

export default function useHashParam(
  defaultValue = ""
): [string, (newHash: string) => void] {
  const currentValue = getHashParam(defaultValue);
  const [innerValue, setInnerValue] = React.useState(currentValue);

  React.useEffect(() => {
    const handleHashChange = () => {
      const newValue = getHashParam(defaultValue);
      setInnerValue(newValue);
    };
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, [defaultValue]);

  const setValue = React.useCallback(
    (value) => {
      if (typeof value === "function") {
        setHashParam(value(getHashParam(defaultValue)));
      } else {
        setHashParam(value);
      }
    },
    [defaultValue]
  );

  return [innerValue || defaultValue, setValue];
}
