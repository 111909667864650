import React from "react";
import styled from "styled-components";

const BaseButton = styled.a`
  padding: 0.5rem 1.2rem;
  display: inline-block;
  transition: background-color 0.1s;
  font-weight: 700;
  color: #222;
  font-size: 1rem;
  line-height: 1.5;
  border: 0;
`;

export const Button = styled(BaseButton)`
  background-color: #404040;
  color: white !important;
`;
export const ButtonSecondary = styled(BaseButton)`
  background-color: #d9d9d9;
  margin-left: 0.5rem;
`;
